import React from "react";

interface SessionVars {
  id: number;
}

export function Session({ id }: SessionVars) {
  function removeSession() {}
  return (
    <div>
      <p>Session is started</p>
      <button onClick={() => removeSession()}>End Session</button>
    </div>
  );
}

import React, { useState } from "react";
import { Session } from "./Session";

export function SessionHandler({ sessions = 0 }) {
  const [session, sessionSet] = useState(sessions);
  const definitely_return = (
    <div>
      <button onClick={() => sessionSet((session) => session + 1)}>
        Start a Game
      </button>
    </div>
  );
  let as = [];
  for (let i = 0; i < session; i++) {
    as.push(i);
  }
  let items;
  if (session !== 0) {
    items = as.map((a) => <Session id={a}></Session>);
  }
  return (
    <div className="SessionParent">
      {definitely_return} {items}
    </div>
  );
}

import React from "react";
import logo from "./logo.svg";
import { SessionHandler } from "./SessionHandler";
import "./App.css";

function App() {
  return (
    <div className="App">
      <h1>Hell Points</h1>
      <SessionHandler sessions={1} />
    </div>
  );
}

export default App;
